import { Ecommerce, ShoppingCartHelpers } from "@with-nx/ecommerce";
import { Tooltip } from "@with-nx/editor-ui";
import {
  Currency,
  StageGearController,
  StageGearProduct,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import { DesignedButton, DesignedSelect } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { StageGearHelpers } from "libs/hooks-n-helpers/src/stage-gear/StageGearHelpers";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { CiClock2 } from "react-icons/ci";
import { Box } from "simple-effing-primitive-layout";

import DesignedIcon from "../../../../atoms/src/lib/designed-icon/designed-icon";
import DesignedTag from "../../../../atoms/src/lib/designed-tag/designed-tag";
import Rule from "../../../../atoms/src/lib/rule/rule";
import Colors from "../../../../helpers/src/lib/Colors";
import GenericContentHolder from "../../../../organisms/src/lib/generic-content-holder/generic-content-holder";

export interface CustomizableCartItemProps {
  title: string;
  badge: string;
  image?: string;
  licensed?: boolean;
  price: string;
  discount?: string;
  licensor?: string;
  abbreviation?: string;
  product:
    | Ecommerce.Schemas.LicensedItemSchemaType
    | Ecommerce.Schemas.NonLicensedItemSchemaType;
  production?: Ecommerce.Payload.Production.CreateProduction;
  onRemove?: () => unknown;
  type: "licensed" | "non-licensed" | "stage_gear";
  productions?: Ecommerce.Payload.Production.CreateProduction[];
  onProductionUpdate?: (p: string) => unknown;
  onBack?: () => unknown;
}

export const CustomizableCartItem = ({
  title,
  badge,
  licensed,
  price,
  licensor,
  abbreviation,
  product,
  production,
  onRemove,
  type,
  productions,
  onProductionUpdate,
  onBack,
}: CustomizableCartItemProps) => {
  const region = useServerRegion();
  const [open, _open] = useState(true);
  const [available, _available] = useState(false);

  const parsedPrice = parseFloat(price || "0");

  const areDetailsFilled = ShoppingCartHelpers.productionDetailsValid(type, {
    ...production,
    additionalWeeks: production?.additionalWeeks || 0,
  });

  const areDetailsError = ShoppingCartHelpers.productionDetailsError(type, {
    ...production,
    additionalWeeks: production?.additionalWeeks || 0,
  });

  const stageGearStartError = useMemo(() => {
    if (type === "stage_gear" && product?.rentalStart) {
      const rentalStart = moment(product?.rentalStart);
      const today = moment();
      const daysLeft = rentalStart.diff(today, "days");
      if (daysLeft <= 7) {
        return `Unfortunately, the delivery date must be at least 7 days from today. Please remove this item to proceed with your order.`;
      }
    }
  }, [type, product?.rentalStart]);

  let subTitle = "";

  if (type === "stage_gear" && !product?.product?.gearResource?.isPurchasable) {
    const weeks =
      type == "stage_gear" && product?.rentalStart && product?.rentalEnd
        ? moment(product.rentalEnd).diff(moment(product.rentalStart), "weeks")
        : 0;

    if (weeks > 0) {
      subTitle = `(${weeks} week${weeks > 1 ? "s" : ""})`;
    }
  }

  useEffect(() => {
    if (
      type === "stage_gear" &&
      product?.rentalStart &&
      product?.rentalEnd &&
      product?.product.id
    ) {
      StageGearController.methods
        .available(
          product?.product?.id,
          product.rentalStart,
          product.rentalEnd,
          !!product?.product?.gearResource?.isPurchasable
        )
        .then((available) => {
          _available(available);
        })
        .catch((error) => console.log("error checking availability", error));
    }
  }, [type, product?.rentalStart, product?.rentalEnd, product?.product.id]);

  return (
    <Box
      parse="br:10 pa:20 mb:12"
      color={Colors.foreground}
      native={{
        cypress: "cart-item",
      }}
    >
      <Box parse="d:flex a:center j:space-between">
        <Box n="image-and-title" parse="f:1 d:flex a:center">
          <Box parse="mr:16">
            <GenericContentHolder
              fit="cover"
              parse="w:85 h:56 br:10 oa:hidden"
              image={(() => {
                if (product?.product?.type === "STAGE_GEAR") {
                  const image =
                    product?.product.gearResource?.attachments?.find(
                      (a) => a.name === "image_thumbnail"
                    )?.blob?.signedUrl;

                  return image;
                }

                if (product?.product?.type === "SCENIC_PROJECTION_PACKAGE") {
                  return product?.product?.scenicProjection?.show?.attachments?.find(
                    (a) => a.name === "logo"
                  )?.blob?.signedUrl;
                }

                if (product?.product?.type === "CHOREO_GUIDE") {
                  return product?.product?.choreoGuide?.show?.attachments?.find(
                    (a) => a.name === "logo"
                  )?.blob?.signedUrl;
                }

                if (product?.product?.type === "DIGITAL_DROP") {
                  return product?.product?.digitalDrop?.attachments?.find(
                    (i) => i.name === "preview_still"
                  )?.blob?.signedUrl;
                }

                return undefined;
              })()}
              alt={title}
            />
          </Box>
          <Box parse="d:inline-flex fd:column">
            <Rule rule="lm" color={Colors.font1} bottom={2}>
              {type === "stage_gear"
                ? StageGearHelpers.meta(
                    product?.product?.gearResource as StageGearProduct
                  ).name
                : title}{" "}
              {subTitle}
            </Rule>
            {type === "stage_gear" &&
            StageGearHelpers.meta(
              product?.product?.gearResource as StageGearProduct
            ).additional ? (
              <Rule rule="ls" color={Colors.font4} bottom={4}>
                {
                  StageGearHelpers.meta(
                    product?.product?.gearResource as StageGearProduct
                  ).additional
                }
              </Rule>
            ) : undefined}
            <Box parse="d:inline-flex">
              {badge ? (
                <DesignedTag
                  background={Colors.accent}
                  size="small"
                  label={badge}
                />
              ) : undefined}
            </Box>
          </Box>
        </Box>
        <Box parse="d:inline-flex fd:column a:flex-end">
          <button onClick={onRemove}>
            <DesignedIcon name="multiply/bold" size={16} color={Colors.font4} />
          </button>

          <Box parse="d:inline-flex a:center">
            <Rule parse="!lm" color={Colors.font2} bottom={2}>
              {Currency.format(parsedPrice, region?.currency?.code || "USD")}
            </Rule>
          </Box>
          {licensed ? (
            <Box parse="d:inline-flex a:center">
              <Rule parse="!ls" color={Colors.warning} right={4}>
                Billed through {abbreviation}
              </Rule>
              <Tooltip
                position="top-center"
                label={`Your order will be added to your ${licensor} account and billed through them. Delivery may take up to 2 business days while we verify rights with the partner. If you have any questions please call us at 1-800-277-0343`}
              >
                <DesignedIcon name="info/bold" size={16} color={Colors.font4} />
              </Tooltip>
            </Box>
          ) : undefined}
        </Box>
      </Box>
      <Box parse="w:100% h:1 mt:16 mb:16" color={Colors.accent} />
      <Box parse="d:flex a:center" press={() => _open(!open)}>
        <DesignedIcon
          name={open ? "up" : "down"}
          weight="bold"
          color={!open ? Colors.warning : Colors.font4}
          size={20}
        />
        <Rule parse="!ls ml:8 mr:20">Enter production details</Rule>
        {areDetailsFilled ? (
          <Box parse="d:inline-flex">
            <DesignedIcon name="check/bold" color={Colors.positive} size={16} />
            <Rule color={Colors.positive} parse="!lt ml:5">
              Complete
            </Rule>
          </Box>
        ) : undefined}
      </Box>
      {open ? (
        <Box parse="mt:16">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Row gutter={[18, 18]} style={{ marginBottom: 16 }}>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <DesignedSelect
                    label="Production"
                    empty
                    value={production?.id ? String(production?.id) : undefined}
                    change={(p) => onProductionUpdate?.(p)}
                    options={
                      productions?.map((p) => [
                        String(p.id),
                        String(p.showTitle),
                      ]) || []
                    }
                    native={{
                      cypress: `select-production`,
                    }}
                  />
                </Col>
                {!production ? (
                  <Col xs={24} sm={12} md={12} lg={18}>
                    <Box
                      parse="pa:8 mt:20 br:5 d:flex a:flex-start"
                      color="rgba(252,112,42,0.1)"
                    >
                      <Box parse="f:1 mr:16">
                        <Rule parse="!lt" color="var(--warning)">
                          Please select one of the productions you have
                          previously created.
                        </Rule>
                      </Box>
                    </Box>
                  </Col>
                ) : undefined}
                {!!production?.lastPerformance?.length &&
                ["SCENIC_PROJECTION_PACKAGE", "DIGITAL_DROP"].includes(
                  product.product.type || ""
                ) ? (
                  <>
                    <Col xs={24} sm={12} md={12} lg={6}>
                      <Rule
                        parse="!lt d:block d:flex a:center"
                        color={Colors.font4}
                        bottom={12}
                      >
                        Rehearsal Material
                        <Tooltip
                          position="top-center"
                          label="Typical processing for order approval is 3 business days."
                        >
                          <Box parse="d:inline-flex ml:6">
                            <DesignedIcon
                              name="info/bold"
                              color={Colors.font4}
                              size={12}
                            />
                          </Box>
                        </Tooltip>
                      </Rule>
                      <Rule parse="!ls d:block" color={Colors.font2}>
                        Upon order approval
                      </Rule>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                      <Rule
                        parse="!lt d:flex a:center"
                        color={Colors.font4}
                        bottom={12}
                      >
                        Full Access Begins
                        <Tooltip
                          position="top-center"
                          label="You will receive access to watermarked content upon order approval, and the watermark will be removed on the date Full Access Begins. If you need access to the un-watermarked content earlier, add additional weeks."
                        >
                          <Box parse="d:inline-flex ml:6">
                            <DesignedIcon
                              name="info/bold"
                              color={Colors.font4}
                              size={12}
                            />
                          </Box>
                        </Tooltip>
                      </Rule>
                      <Rule parse="!ls d:block" color={Colors.font2}>
                        {moment(production.lastPerformance)
                          .subtract(
                            4 + (production.additionalWeeks || 0),
                            "weeks"
                          )
                          .format("D MMM YYYY")}
                      </Rule>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                      <Rule
                        parse="!lt d:block"
                        color={Colors.font4}
                        bottom={12}
                      >
                        Access Expires
                      </Rule>
                      <Rule parse="!ls d:block" color={Colors.font2}>
                        {moment(production.lastPerformance)
                          .add(1, "days")
                          .format("D MMM YYYY")}
                      </Rule>
                    </Col>
                  </>
                ) : undefined}
                {!!production?.lastPerformance?.length &&
                ["CHOREO_GUIDE"].includes(product.product.type || "") ? (
                  <>
                    <Col xs={24} sm={12} md={12} lg={6}>
                      <Rule
                        parse="!lt d:block d:flex a:center"
                        color={Colors.font4}
                        bottom={12}
                      >
                        Rehearsal Material
                        <Tooltip
                          position="top-center"
                          label="Typical processing for order approval is 3 business days."
                        >
                          <Box parse="d:inline-flex ml:6">
                            <DesignedIcon
                              name="info/bold"
                              color={Colors.font4}
                              size={12}
                            />
                          </Box>
                        </Tooltip>
                      </Rule>
                      <Rule parse="!ls d:block" color={Colors.font2}>
                        Upon order approval
                      </Rule>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                      <Rule
                        parse="!lt d:flex a:center"
                        color={Colors.font4}
                        bottom={12}
                      >
                        Full Access Begins
                        <Tooltip
                          position="top-center"
                          label="You will receive access to watermarked content upon order approval, and the watermark will be removed on the date Full Access Begins. If you need access to the un-watermarked content earlier, add additional weeks."
                        >
                          <Box parse="d:inline-flex ml:6">
                            <DesignedIcon
                              name="info/bold"
                              color={Colors.font4}
                              size={12}
                            />
                          </Box>
                        </Tooltip>
                      </Rule>
                      <Rule parse="!ls d:block" color={Colors.font2}>
                        {moment(production.lastPerformance)
                          .subtract(
                            4 + (production.additionalWeeks || 0),
                            "weeks"
                          )
                          .subtract(1, "years")
                          .format("D MMM YYYY")}
                      </Rule>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                      <Rule
                        parse="!lt d:block"
                        color={Colors.font4}
                        bottom={12}
                      >
                        Access Expires
                      </Rule>
                      <Rule parse="!ls d:block" color={Colors.font2}>
                        {moment(production.lastPerformance)
                          .add(1, "days")
                          .format("D MMM YYYY")}
                      </Rule>
                    </Col>
                  </>
                ) : undefined}

                {!!production?.lastPerformance?.length &&
                ["STAGE_GEAR"].includes(product.product.type || "") ? (
                  <>
                    {!product?.product?.gearResource?.isPurchasable ? (
                      <>
                        <Col xs={24} sm={12} md={12} lg={6}>
                          <Rule
                            parse="!lt d:flex a:center"
                            color={Colors.font4}
                            bottom={12}
                          >
                            Delivery date
                          </Rule>
                          <Rule parse="!ls d:block" color={Colors.font2}>
                            {moment(product.rentalStart).format("D MMM YYYY")}
                          </Rule>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                          <Rule
                            parse="!lt d:block"
                            color={Colors.font4}
                            bottom={12}
                          >
                            Return Date
                          </Rule>
                          <Rule parse="!ls d:block" color={Colors.font2}>
                            {moment(product.rentalEnd).format("D MMM YYYY")}
                          </Rule>
                        </Col>
                      </>
                    ) : undefined}
                  </>
                ) : undefined}
              </Row>
            </Col>
          </Row>
        </Box>
      ) : undefined}
      {production && areDetailsError ? (
        <Box parse="pa:16 br:5 d:flex a:flex-start" color="rgba(252,42,80,0.1)">
          <Box parse="f:1 mr:16">
            <Rule parse="!lt" color="var(--negative)">
              {areDetailsError}
            </Rule>
          </Box>
          <DesignedButton
            label="Go Back"
            theme="white"
            size="extraSmall"
            press={onBack}
          />
        </Box>
      ) : undefined}
      {stageGearStartError ? (
        <Box parse="pa:16 br:5 d:flex a:flex-start" color="rgba(252,42,80,0.1)">
          <Box parse="f:1 mr:16">
            <Rule parse="!lt" color="var(--negative)">
              {stageGearStartError}
            </Rule>
          </Box>
          <DesignedButton
            label="Remove Item"
            theme="white"
            size="extraSmall"
            press={onRemove}
          />
        </Box>
      ) : undefined}

      {type === "stage_gear" && available ? (
        <Box
          parse="d:flex a:center mt:8 br:8 pa:8 mxw:400"
          border="0.4px solid var(--primary)"
        >
          <CiClock2 size={20} color="var(--primary)" />{" "}
          <Rule parse="!ls d:block ml:4 c:var(--primary)">
            Check out now, these items aren't reserved.
          </Rule>
        </Box>
      ) : undefined}
    </Box>
  );
};

export default CustomizableCartItem;
